import Button from "components/form/Button/Button";
import arrowRight from "icons/arrow-right.svg";
import Anchor from "components/ui/Anchor/Anchor";
import { showDemoModal } from "components/demo-modal/DemoModal";
import "./hero.styles.css";

export default function Hero() {
  return (
    <Anchor id="home">
      <div className="hero container">
        <div className="hero__in">
          <h1 className="hero__title">
            <span className="hero__title-text">
              GO <span className="primary--text">$PHYGITON!</span>
            </span>
            <span className="hero__title-text">
              <span className="hero__title-text--mobile">Coming</span>
              <span className="primary--text"> Soon!</span>
            </span>
          </h1>
        </div>

        <div className="hero__footer">
          <div className="hero__cta">
            <Button
              text="Join Community"
              size="m"
              icon={arrowRight}
              onClick={() => {
                window.location.href = "https://t.me/phygiton";
              }}
            />
          </div>

          <p className="hero__text">Stay tuned!</p>
        </div>
      </div>
    </Anchor>
  );
}
