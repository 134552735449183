import Hero from "./components/Hero/Hero";
import Discover from "./components/Discover/Discover";
import CTA from "./components/CTA/CTA";
import Features from "./components/Features/Features";
import About from "./components/About/About";

export default function Home() {
  return (
    <div>
      <Hero />
    </div>
  );
}
