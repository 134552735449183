import React from 'react';
import Sticky from 'react-sticky-el/lib/basic-version';
import cx from 'classnames';
import { PoliciesNavList } from 'components/policies/nav-list';
import Anchor from 'components/ui/Anchor/Anchor';
import css from './CookiePolicy.module.scss';
import ScrollToTop from 'utils/ScrollToTop';
import { Content } from 'components/Content/Content';

const CookiePolicyPage = () => {
  return (
    <>
      <ScrollToTop />
      <section id='cookies-policy' className={css.root}>
        <div className='container'>
          <div id='cookie-boundary-element' className={cx('row', css.grid)}>
            <div className={cx('col', css.left)}>
              <Sticky
                boundaryElement='#cookie-boundary-element'
                hideOnBoundaryHit={false}
                wrapperClassName={css.stickyNavigation}
              >
                <div className={css.stickyWrapper}>
                  <div className={css.stickyScroller}>
                    <PoliciesNavList
                      containerSelector='#cookies-policy'
                      defaultActiveKey='cookie'
                    />
                  </div>
                </div>
              </Sticky>
            </div>
            <div className={cx('col', css.right)}>
              <Content>
                <Anchor id='terms-of-use'>
                  <h2 className='h3 spacing-top-0'>Terms of Use</h2>
                  <p>
                    This Cookies Policy describes how Ticketmeta (“Company”,
                    “we”, “our”, or “us”) collects, uses, shares, and stores
                    cookies in relation to its website, Ticketmeta.io (the
                    “Site”). This Policy applies to the Site, applications,
                    products and services if any (collectively, “Services”) on
                    or in which it is posted, linked, or referenced on the
                    website.
                    <br />
                    We understand that your privacy is important to you and are
                    committed to being transparent about the technologies we
                    use. In the spirit of transparency, this Policy provides
                    detailed information about how and when we use cookies on
                    our Sites.
                    <br />
                    Do we use Cookies?
                    <br />
                    Yes. We and our marketing partners, affiliates, and
                    analytics or service providers use cookies, web beacons, or
                    pixels and other technologies to ensure everyone who uses
                    the Sites has the best possible experience.
                  </p>
                </Anchor>

                <Anchor id='whats-is-a-cookie'>
                  <h2>What is a Cookie?</h2>
                  <p>
                    A cookie (“Cookie”) is a small text file that is placed on
                    your hard drive by a web page server. Cookies contain
                    information that can later be read by a web server in the
                    domain that issued the cookie to you. Some of the cookies
                    will only be used if you use certain features or select
                    certain preferences, and some cookies will always be used.
                    You can find out more about each cookie by viewing our
                    current cookie list below. We update this list periodically,
                    so there may be additional cookies that are not yet listed.
                    Web beacons, tags and scripts may be used in the Sites or in
                    emails to help us to deliver cookies, count visits,
                    understand usage and campaign effectiveness and determine
                    whether an email has been opened and acted upon. We may
                    receive reports based on the use of these technologies by
                    our service/analytics providers on an individual and
                    aggregated basis.
                  </p>
                  <h2 className='h3'>Why do we use Cookies?</h2>
                  <p>We generally use cookies for the following purposes:</p>
                  <ul>
                    <li>To recognize new or past customers.</li>
                    <li>
                      To improve our Site and to better understand your visits
                      on our platforms and Site
                    </li>
                    <li>
                      To serve you with interest-based or targeted advertising.
                    </li>
                    <li>
                      To observe your behaviors and browsing activities over
                      time across multiple websites or other platforms.{' '}
                    </li>
                    <li>
                      To better understand the interests of our customers and
                      visitors to our Site.
                    </li>
                    <li>
                      Advertising Cookies assist in delivering ads to relevant
                      audiences and having our ads appear at the top of search
                      results.
                    </li>
                  </ul>
                  <p>
                    It is crucial to note that different Cookies perform
                    different functions. The list of Cookies used on the website
                    is provided below.
                  </p>
                </Anchor>

                <Anchor id='сookie-categories-according-to-its-purpose'>
                  <h2 className='semi-bold'>
                    Cookie categories according to its purpose
                  </h2>
                  <h3>NecessaryCookies</h3>
                  <p>
                    These Cookies are required for the websites to function
                    properly; they allow you to navigate the websites and enjoy
                    their features. You would be unable to navigate between
                    pages or use certain essential functions of the websites if
                    these were not there. If you do not want us to gather{' '}
                    <br className='break-line' />
                    such information, you must immediately stop using the
                    websites. By continuing to use the websites, you agree that
                    these Cookies are required for the provision of such
                    services and will be set on your browser as is customary.
                  </p>
                  <h3>Targeting or Advertising Cookies</h3>
                  <p>
                    These Cookies are used to offer more relevant information to
                    you and your interests. They may be used to offer tailored
                    advertising or to limit the number of times an advertisement
                    is displayed to you.
                  </p>
                  <h3>Functionality Cookies</h3>
                  <p>
                    These Cookies enable the websites to remember if you have
                    previously visited the Websites as well as the choices you
                    make (such as your language, region, or preferences) so as
                    to give you a more personalized online experience.
                  </p>
                  <h3>Performance Cookies:</h3>
                  <p>
                    These Cookies gather and report on aggregate
                    non-identifiable information, which assists us in
                    understanding the performance of the websites and gives
                    insights into how the website is currently used and how it
                    might be improved for users. These Cookies save information
                    such as the sections you have visited, the amount of time
                    you have spent on the websites, and any problems you have
                    encountered, such as error messages.
                  </p>
                  <p className='quote'>
                    it is not possible to reject their use.
                  </p>
                  <p>
                    The table below contains further information about the
                    individual Cookies used on our website or in emails we send,
                    as well as the purposes for which they are used:
                  </p>
                  <div className='table'>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h4>
                          Performance cookies, <br />
                          Targeting/Advertising cookies
                        </h4>
                      </div>
                      <div className='tableColumn'>
                        <p>
                          Ad Targeting, Analytics/Measurement, Content
                          Customization, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='www.woopra.com/privacy/'
                            target='__blank'
                            rel='noreferrer'
                          >
                            www.woopra.com/privacy/
                          </a>
                        </p>
                        <p>
                          Ad Serving, Ad Targeting, Analytics/Measurement,
                          Content Customization, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='support.google.com/ads/answer/2662922'
                            target='__blank'
                            rel='noreferrer'
                          >
                            support.google.com/ads/answer/2662922
                          </a>
                        </p>
                        <p>
                          Ad Targeting, Analytics/ Measurement. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='kenshoo.com/privacy-policy/'
                            target='__blank'
                            rel='noreferrer'
                          >
                            kenshoo.com/privacy-policy/
                          </a>
                        </p>
                        <p>
                          Ad Serving, Ad Targeting, Analytics/Measurement,
                          Content Customization, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='home.neustar/privacy'
                            target='__blank'
                            rel='noreferrer'
                          >
                            home.neustar/privacy
                          </a>
                        </p>
                        <p>
                          Ad Targeting, Analytics/ Measurement, Content
                          Customization, Optimization. <br />
                          You can learn about this third party cookies at{' '}
                          <a
                            href='help.twitter.com/en/rules-and-policies/twittercookies'
                            target='__blank'
                            rel='noreferrer'
                          >
                            help.twitter.com/en/rules-and-policies/twitter
                            <br className='break-line' />
                            cookies
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h4>Performance cookies</h4>
                      </div>
                      <div className='tableColumn'>
                        <p>
                          Analytics/Measurement, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='optimizely.com/legal/opt-out/'
                            target='__blank'
                            rel='noreferrer'
                          >
                            optimizely.com/legal/opt-out/
                          </a>
                        </p>
                        <p>
                          Analytics/Measurement, Optimization <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='adobe.com/privacy/opt-out.html'
                            target='__blank'
                            rel='noreferrer'
                          >
                            adobe.com/privacy/opt-out.html
                          </a>
                        </p>
                        <p>
                          Ad Targeting, Analytics/Measurement, Optimization{' '}
                          <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='tools.google.com/dlpage/gaoptout'
                            target='__blank'
                            rel='noreferrer'
                          >
                            tools.google.com/dlpage/gaoptout
                          </a>
                        </p>
                        <p>
                          Analytics/Measurement. <br />
                          You can learn about this third-party cookie at{' '}
                          <a
                            href='mouseflow.com/opt-out/'
                            target='__blank'
                            rel='noreferrer'
                          >
                            mouseflow.com/opt-out/
                          </a>
                        </p>
                        <p>
                          Ad Targeting, Analytics/Measurement, Content
                          Customization, Optimization, <br />
                          <a
                            href='www.baidu.com/duty/yinsiquan.html'
                            target='__blank'
                            rel='noreferrer'
                          >
                            www.baidu.com/duty/yinsiquan.html
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h4>Performance cookies</h4>
                      </div>
                      <div className='tableColumn'>
                        <p>
                          Ad Serving, Ad Targeting, Analytics/Measurement,
                          Content Customization, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='tapad.com/privacy-policy'>
                            tapad.com/privacy-policy
                          </a>
                        </p>
                        <p>
                          Content Customization, Optimization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='oracle.com/marketingcloud/opt-status.html'>
                            oracle.com/marketingcloud/opt-status.html
                          </a>
                        </p>
                        <p>
                          Ad Serving, Ad Targeting, Content Customization.{' '}
                          <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='facebook.com/policies/cookies/'>
                            facebook.com/policies/cookies/
                          </a>
                        </p>
                        <p>
                          Ad Serving, Ad Targeting, Analytics/Measurement.{' '}
                          <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='privacy.microsoft.com/en-us/privacystatement'>
                            privacy.microsoft.com/en-us/privacystatement
                          </a>
                        </p>
                        <p>
                          Content Customization. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='help.survicate.com/faq/what-sort-of-cookies-doe'>
                            help.survicate.com/faq/what-sort-of-cookies-doe
                          </a>
                        </p>
                        <p>
                          Ad Targeting. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='owneriq.com/privacy-notice'>
                            owneriq.com/privacy-notice
                          </a>
                        </p>
                        <p>
                          Ad Targeting. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='policies.oath.com/xw/en/oath/privacy/intl/index.html'>
                            policies.oath.com/xw/en/oath/privacy/intl/
                            <br className='break-line' />
                            index.html
                          </a>
                        </p>
                        <p>
                          User email campaign. <br />
                          You can learn about this third-party cookie at{' '}
                          <a href='mailchimp.com/legal/cookies/'>
                            mailchimp.com/legal/cookies/
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Anchor>

                <Anchor id='сookie-categories-according-to-the-time-they-remain-activated'>
                  <h2 className='semi-bold'>
                    Cookie categories according to the time they remain
                    activated
                  </h2>
                  <h3>Session cookies:</h3>
                  <p>
                    These cookies are assigned to the device from which the user
                    is browsing only for the duration of the visit to the web
                    portal, since they disappear automatically when the user
                    closes the browser. They are usually used to store
                    information that is necessary the provision of the service
                    requested by the user on a single occasion (for example, a
                    list of products purchased) and appear at the end of the
                    session.
                  </p>
                  <h3>Persistent cookies:</h3>
                  <p>
                    Persistent cookies imply that the data continues to be
                    stored on the device or terminal of the user, being its
                    temporary duration the minimum essential depending on the
                    purpose of its use. The temporary duration of persistent
                    cookies we use and their expiration date can be consulted
                    below.
                  </p>
                </Anchor>

                <Anchor id='how-to-configure-or-reject-cookies'>
                  <h2 className='semi-bold'>
                    How to configure or reject cookies?
                  </h2>
                  <p>
                    You can activate or deactivate the use of cookies directly
                    from the banner or cookie notice and the associated
                    preferences panel that will appear on your first visit to
                    our website, as well as at any time at the beginning of this
                    Cookies Policy. If you click on “Accept all and continue”{' '}
                    <br className='break-line' />
                    on the banner, you will be allowing the use of all cookies.
                    If you do not want us to use any of the cookies, you can
                    select it in the configuration panel, by clicking “Configure
                    / Reject cookies” on the banner.
                  </p>
                  <p>
                    This configuration panel will be divided according to the
                    purposes of cookies used in each website, since you may want
                    to allow cookies for some purposes, but not for others:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      Technical cookies (These are strictly necessary for the
                      operation and security of the website and cannot be
                      deactivated)
                    </li>
                    <li>Analytics cookies</li>
                    <li>Personalization cookies</li>
                    <li>Advertising cookies Social Media cookies</li>
                  </ul>
                  <p>
                    Within this panel, you will be able to enable or disable all
                    non-strictly necessary cookies (analytical, personalization,
                    advertising and social media cookies). If you disable them,
                    we will stop collecting this data (or we will not collect
                    any, If you do not consent in the first visit) and / or
                    sending <br className='break-line' />
                    it to the aforementioned third parties.
                  </p>
                  <p>
                    In addition, you can allow, block or delete these cookies
                    through the configuration options of your internet browser:
                  </p>
                  <ul className='spacing-top'>
                    <li>Chrome</li>
                    <li>Internet Explorer</li>
                    <li>Firefox</li>
                    <li>Safari</li>
                    <li>Safari for IOS (iPhone and iPad) Chrome for Android</li>
                  </ul>
                </Anchor>

                <Anchor id='how-long-do-cookies-last-on-your-computer'>
                  <h2 className='semi-bold'>
                    How long do cookies last on your computer?
                  </h2>
                  <p>
                    Cookies will be used solely and exclusively for the time
                    necessary to fulfill the purposes for which it has been
                    indicated in each cookie. You can find out each cookie
                    storage period in section 3 of this document. Once the
                    cookie has expired, it can be renewed if the user accepts
                    its use again.
                  </p>
                  <p>
                    Update of the Cookies Policy <br />
                    Ticketmeta may update this Cookies Policy at any time. This
                    update will be applicable from its publication on the
                    website so users are advised to review it periodically.
                  </p>
                  <p>
                    Notwithstanding the foregoing, the inclusion of new cookies
                    or the use of cookies already informed for purposes other
                    than those indicated will require their prior acceptance by
                    the user.
                  </p>

                  <h2>How to disable Cookies?</h2>
                  <p>
                    Cookies are either “session” Cookies which are deleted when
                    you end your browser session, or “persistent,” which remain
                    until their deletion by you (discussed below) or the party
                    who served the cookie.
                  </p>
                  <p>
                    You can generally activate or later deactivate the use of
                    Cookies through a functionality built into your web browser.
                    To learn more about how to control cookie settings through
                    your browser:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      <a href='/' target='__blank' rel='noreferrer'>
                        Click here
                      </a>{' '}
                      to learn more about the “Private Browsing” setting and
                      managing cookie settings in Firefox; Click here to learn
                      more about “Incognito” and managing cookie settings in
                      Chrome;
                    </li>
                    <li>
                      <a href='/' target='__blank' rel='noreferrer'>
                        Click here
                      </a>{' '}
                      to learn more about “InPrivate” and managing cookie
                      settings in Internet Explorer; or
                    </li>
                    <li>
                      <a href='/' target='__blank' rel='noreferrer'>
                        Click here
                      </a>{' '}
                      to learn more about “Private Browsing” and managing cookie
                      settings in Safari.
                    </li>
                    <li>
                      If you want to learn more about cookies, or how to
                      control, disable or delete them, please visit{' '}
                      <a
                        href='https://www.aboutcookies.org/'
                        target='__blank'
                        rel='noreferrer'
                      >
                        aboutcookies.org
                      </a>{' '}
                      for detailed guidance.
                    </li>
                  </ul>
                  <p>
                    In addition, certain third party advertising networks,
                    including Google, permit users to top out of or customize
                    preferences associated with your internet browsing. To learn
                    more about this feature from Google,{' '}
                    <a href='/' target='__blank' rel='noreferrer'>
                      click here
                    </a>
                    .
                  </p>
                  <p>
                    To control flash Cookies, which we may use on our Sites from
                    time to time, you can{' '}
                    <a href='/' target='__blank' rel='noreferrer'>
                      go to this link
                    </a>{' '}
                    because Flash cookies cannot be controlled through your
                    browser settings.
                  </p>
                  <p>
                    Please note that if you decline the use of Cookies, some
                    functions of the Sites may be unavailable and we will not be
                    able to present personally tailored content and
                    advertisements to you.
                  </p>
                  <p>
                    We may link the information collected by Cookies with other
                    information we collect from you pursuant to this Privacy
                    Policy and use the combined information as set forth herein.
                    Similarly, the third parties who serve cookies on our Sites
                    may link your name or email address to other information
                    they collect, which may include past purchases made offline
                    or online, or your online usage information. If you are
                    located in the European Economic Area, you have certain
                    rights that are described above under the header “Notice to
                    EU Data Subjects”, including the right to inspect and
                    correct or delete the data that we have about you.
                  </p>
                </Anchor>

                <Anchor id='local-storage-policy'>
                  <h2>Local storage policy</h2>
                  <p>
                    Local Storage, including Javascript-enabled local Storage,
                    is a typical way for a website to store a small file of
                    letters and numbers in your browser. We use Local Storage in
                    some of our Services in order to assign you a unique user ID
                    for purposes of communication with you. Local Storage is
                    deleted when the website that stored them deletes them. You
                    can also delete Local Storage from your browser at any time
                    you like by visiting your web browser settings.
                  </p>
                  <p>How to Clear Local Storage from Your Browser:</p>
                  <ul className='spacing-top'>
                    <li>
                      In Firefox, local Storage is cleared when the following
                      conditions are met: (a) user clears recent history, (b)
                      cookies are selected to be cleared, and (c) time range is
                      “Everything”.
                    </li>
                    <li>
                      In Chrome, local Storage is cleared when the following
                      conditions are met: (a) clear browsing data, (b) “cookies
                      and other site data” is selected, and (c) time frame is
                      “from beginning of time. In Chrome it is also now possible
                      to delete local Storage for one specific site.
                    </li>
                    <li>
                      In IE, to clear local Storage: (a) click on Tools–Internet
                      Options, (b) General tab, (c) delete browsing history on
                      exit, (d) ensure “Cookies and website data” (or “temporary
                      internet files and website files”) is selected, and (e)
                      consider unchecking “Preserve Favorites website data” at
                      the top.
                    </li>
                    <li>
                      In Safari, (a) Click Safari, (b) Preferences, (c) Select
                      the Privacy tab, (d) Click Remove all website data and (e)
                      Click Remove Now
                    </li>
                  </ul>
                  <p>
                    Our cookie usage may vary from time to time. When they do,
                    we will update this Cookies Policy by publishing an updated
                    version with a new “Last Updated” date.
                  </p>
                </Anchor>

                <h2>Contact us</h2>
                <p>
                  If you have any questions or issues about this Cookies Policy,
                  please contact us using the details provided above or send us
                  an email at{' '}
                  <a
                    href='mailto:Legal@mundocrypto.com'
                    target='__blank'
                    rel='noopener'
                  >
                    Legal@mundocrypto.com
                  </a>
                </p>
              </Content>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyPage;
