import React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import cx from 'classnames';
import { ReactComponent as LinkedIn } from 'icons/linkedin.svg';
import { ReactComponent as LocationIcon } from 'icons/location.svg';
import { ReactComponent as PhoneIcon } from 'icons/phone.svg';
import { ReactComponent as EnvelopIcon } from 'icons/envelop.svg';
import { Card } from 'components/ui/Card/Card';
import {
  showFailureModal,
  showSuccessModal,
} from 'components/demo-modal/DemoModal';
import css from './get-in-touch.module.scss';
import {
  sendGetDemoForm,
  sentGetDemoInitialValues,
  sentGetDemoValidationSchema,
} from 'api/sendGetDemoForm';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { SubmitButton } from 'components/form/SubmitButton/SubmitButton';

export const GetInTouch = () => {
  const onSubmitHandler = async (values, formik) => {
    try {
      await sendGetDemoForm(values);
      formik.resetForm(sentGetDemoInitialValues);
      showSuccessModal();
    } catch (error) {
      console.log(error);
      showFailureModal();
    }
  };

  return (
    <section className={css.root}>
      <div className='container'>
        <div className={css.grid}>
          <div className={css.left}>
            <h1 className={cx(css.heading, 'heading--primary')}>
              Get in touch
            </h1>
            <div className={css.founders}>
              <div className={css.founder}>
                <AspectRatio ratio={1} className={css.founderImage}>
                  <img
                    src={require('images/contacts-founder-1.png')}
                    alt='Irina Jadallah'
                    loading='lazy'
                    width={210}
                    height={210}
                  />
                </AspectRatio>
                <div className={css.founderTitle}>
                  Irina Jadallah
                  <a
                    href='https://sg.linkedin.com/in/irina-g-jadallah-3a8058175'
                    target='__blank'
                    rel='noreferrer'
                    className={css.linkedin}
                    aria-label='LinkedIn'
                  >
                    <span className="linkedin-blue"><LinkedIn /></span>
                  </a>
                </div>
                <div className={css.founderPosition}>
                  Co-founder • Singapore
                </div>
              </div>

              <div className={css.founder}>
                <AspectRatio ratio={1} className={css.founderImage}>
                  <img
                    src={require('images/contacts-founder-2.png')}
                    alt='Anna Ivanchenko'
                    loading='lazy'
                    width={210}
                    height={210}
                  />
                </AspectRatio>
                <div className={css.founderTitle}>
                  Anna Ivanchenko
                  <a
                    href='https://ae.linkedin.com/in/annivanchenko'
                    target='__blank'
                    rel='noreferrer'
                    className={css.linkedin}
                    aria-label='LinkedIn'
                  >
                    <span className="linkedin-blue"><LinkedIn /></span>
                  </a>
                </div>
                <div className={css.founderPosition}>Co-founder • Dubai</div>
              </div>
            </div>
            <div className={css.contacts}>
              <ul>
                <li>
                  <span>
                    <LocationIcon />
                  </span>
                  <span>
                    Compass Building, Al Shohada Road, AL Hamra Industrial Zone-
                    <br />
                    FZ, RAK, United Arab Emirates
                  </span>
                </li>

                <li>
                  <span>
                    <PhoneIcon />
                  </span>
                  <span className={css.phoneNumbers}>
                    <a href='tel:+6597772488' rel='noopener'>
                      +65 97772488
                    </a>
                    <a href='tel:+971543868322' rel='noopener'>
                      +971 54 386 8322
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <EnvelopIcon />
                  </span>
                  <span className={css.emails}>
                    <a
                      href='mailto:info@ticketmeta.io'
                      target='__blank'
                      rel='noopener'
                    >
                      info@ticketmeta.io
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={css.right}>
            <Card>
              <h2 className={css.cardHeading}>Book a demo</h2>
              <div className={css.getInTouchForm}>
                <Formik
                  validationSchema={sentGetDemoValidationSchema}
                  onSubmit={onSubmitHandler}
                  initialValues={sentGetDemoInitialValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isSubmitting,
                    submitCount,
                    errors,
                  }) => {
                    const isSubmited = !!submitCount;

                    return (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className={css.form}
                      >
                        <Form.Group
                          className={css.formGroup}
                          controlId='validationFormikName'
                        >
                          <Form.Label>
                            Name<span className='label-required'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='e.g San Andreas'
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={isSubmited && !!errors.name}
                          />

                          <Form.Control.Feedback type='invalid'>
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className={css.formGroup}
                          controlId='validationFormikCompany'
                        >
                          <Form.Label>
                            Company name
                            <span className='label-required'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='enter your company name'
                            name='company'
                            value={values.company}
                            onChange={handleChange}
                            isInvalid={isSubmited && !!errors.company}
                          />

                          <Form.Control.Feedback type='invalid'>
                            {errors.company}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className={css.formGroup}
                          controlId='validationFormikEmail'
                        >
                          <Form.Label>
                            Email<span className='label-required'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='email'
                            placeholder='e.g sanandreas@gmail.com'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={isSubmited && !!errors.email}
                          />

                          <Form.Control.Feedback type='invalid'>
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className={css.formGroup}
                          controlId='validationFormikPhone'
                        >
                          <Form.Label>
                            Mobile phone number (optional)
                          </Form.Label>
                          <Form.Control
                            type='tel'
                            placeholder='enter your mobile phone number'
                            name='phone'
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={isSubmited && !!errors.phone}
                          />

                          <Form.Control.Feedback type='invalid'>
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className={css.formGroup}
                          md='6'
                          controlId='validationFormikCase'
                        >
                          <Form.Label>Your case (optional)</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='describe your ploblem here'
                            name='case'
                            as='textarea'
                            value={values.case}
                            onChange={handleChange}
                            isInvalid={isSubmited && !!errors.case}
                            className={css.case}
                          />

                          <Form.Control.Feedback type='invalid'>
                            {errors.case}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <SubmitButton
                          className={cx(css.submitButton, 'button--border-2')}
                          color='primary'
                        >
                          GET DEMO
                        </SubmitButton>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};
